#home {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100vh;

  .welcome {
    margin: 0 auto;
    position: relative;
    max-width: 80%;
    margin-top: -10vh;

    .bg {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      video {
        width: 100%;
        object-fit: cover;
      }

      img {
        position: absolute;
        width: 25%;
        margin: 0 37.5%;
      }
    }
  }

  .bottom {
    width: 100%;
    max-width: 1100px;
    position: absolute;
    bottom: 30px;
    font-size: 16px
  }
}
