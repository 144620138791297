.contact {
  display: flex;
  min-height: 50vh;
  align-items: center;
  
  padding: 0 2em;

  div {
    margin: 0 auto;
  }
}
