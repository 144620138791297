@import "../../theme/breakpoints";

.header {
  display: flex;
  padding: 2em;
  font-size: 13px;
  text-transform: uppercase;

  nav ul {
    margin: 0;
    list-style: none;

    li {
      margin-bottom: 1em;
      text-align: left;

      .active {
        text-decoration: underline;
      }
    }
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  @include tablet-up {

    nav {
      width: 100%;

      ul {
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        li {
          margin-bottom: 0;
        }
      }
    }

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }
  }
}
