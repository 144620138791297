@import "../../theme/breakpoints";

.about {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2em;

  .slideshow {
    width: 96%;
    padding: 1% 2%;

    @include tablet-up {
      width: 50%;
    }

    img {
      width: 100%;
      height: 50vh;
      object-fit: cover;
      // padding-top: 56.25%;
    }
  }

  .text {
    width: 96%;
    text-align: left;
    padding: 0 2%;

    @include tablet-up {
      width: 40%;
    }
  }
}
