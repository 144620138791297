.case-thumbnail {
  flex: 0 0 230px;
  padding-bottom: 2.3em;
  margin: 0 17px;

  .case-content {
    width: 230px;
    margin: 0 auto;
    img {
      width: 230px;
      height: 180px;
      object-fit: cover;
    }

    .desc {
      text-align: left;
      line-height: 1.4;

      .title {
        text-transform: uppercase;
        font-style: italic;
      }

      .client {
        text-transform: lowercase;
        font-weight: bold;
      }
    }
  }

  @media (max-width: 1055px) {
    flex: 0 0 29%;
    // margin: 0 auto;
  }

  @media (max-width: 791px) {
    flex: 0 0 47%;
    margin: 1%;
  }

  @media (max-width: 540px) {
    width: 100%;
    // margin: 4% 0;
    flex: none;

    .case-content {
      width: 86%;
      margin: 0 auto;
      img {
        width: 100%;
      }

      .desc {
        text-align: left;
      }
    }
  }
  //
  // @media (max-width: 440px) {
  //   // padding: 0 2%;
  //   .case-content {
  //     width: 96%;
  //   }
  // }
}
