@import "./theme/breakpoints";

html, body {
  margin: 0 auto;
  height: 100%;
  font-family: 'Courier Prime', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;

  @include tablet-up {
    font-size: 12px;
  }
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  text-decoration: underline;
}
