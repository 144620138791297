@import "../../theme/breakpoints";

.case {
  width: 100%;
  padding: 0 20px;

  .case-container {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    flex-wrap: wrap;

    .hero-video {
      width: 100%;
      padding: 1% 2%;

      iframe {
        width: 100%;
      }
    }

    .info {
      text-align: left;
      padding: 0 2%;
    }

    @include tablet-up {
      flex-wrap: nowrap;

      .hero-video {
        width: 66%;
      }
    }
  }

  hr {
    margin: 0 2%;
  }

  .case-content {
    padding: 40px 2%;

    @include tablet-up {
      p {
        padding: 0 15%;
      }
    }

    iframe {
      max-width: 100%;
    }

    img {
      max-width: 100%;
    }

    figure {
      margin: 3em auto;
    }
  }
}
