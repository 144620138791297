.container {

  min-height: 100%;

  .content {
    max-width: 1100px;
    min-height: 100%;
    margin: 0 auto;
  }
}
